<template>
  <div class="events-detail content">
    <div class="entrants--actions-header pb-2 d-flex justify-content-end">
      <!--      <ClSearch-->
      <!--        placeholder="tableHeader.search"-->
      <!--        @searchUpdate="searchData"-->
      <!--      />-->
      <div class="d-flex align-items-center">
        <!-- FILTER ICON -->
        <!--        <ClFilter-->
        <!--          class="mr-3"-->
        <!--          :isFlag="false"-->
        <!--          @toggleFilter="showColumnFilter"-->
        <!--        />-->
        <CButton
          size="sm"
          class="header-icon-buttons mr-3"
          @click.stop.prevent="handleReload"
        >
          <CIcon name="cil-reload"/>
        </CButton>
      </div>
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody v-if="ready">
            <CDataTable
              id="dataTableId"
              ref="dataTableRef"
              class="zq--table"
              :class="{  }"
              :items="entrantsData"
              :fields="tableFieldsComputed"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #memberId="{ item }">
                <td>
                  <div class="copy-ceil">
                    <router-link :to="{ name: `PreviewMember`, params: {id: item.memberId} }">
                      {{ item.memberId }}
                    </router-link>
                    <i
                      class="fa fa-copy zq-card-row--copy-icon"
                      @click="handleCopy(item.memberId)"
                    />
                  </div>
                </td>
              </template>
              <template #select-filter>
                <div></div>
              </template>
              <template v-for="item in columnFilterFields" #[item]>
                <ColumnFilter
                  :key="item"
                  :field="item.slice(0, -7)"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #actions-filter>
                <div></div>
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              :pages="pages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              @updatePagenation="paginationChange"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ClSearch from '@/shared/components/formComponents/ClSearch';
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import { mapGetters } from 'vuex';
import { pageConfig } from '@/config';
import ClFilter from '@/components/header/ClFilter';
import ColumnFilter from '@/components/table/ColumnFilter';
import axios from 'axios';

export default {
  name: 'EntityEntrants',
  components: {
    ColumnFilter,
    ClFilter,
    ClSearch,
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      entrantsData: [],
      ready: false,
      columnFilter: false,
      isTableScrollbar: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: [
        {
          queryField: 'timestamp',
          order: 'Desc'
        }
      ],
      page: pageConfig.page,
      tableFields: ['memberId', 'spinsLeft'],
      searchableAllFields: ['memberId'],
      query: null,
      multiFields: [],
      columnFilterFields: [
        'memberId-filter',
        'spinsLeft-filter',
      ],
      entriesOptions: [10, 20, 40, 60, 80, 100],
      loading: false,
      totalRecords: 0,
      pages: 1
    };
  },
  props: {
    entityId: String,
    entityType: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    isTipicoSpace() {
      const allowedSpaces = ['tipico5g', 'tipico5g-staging'];
      const currentSpace = localStorage.getItem('zq-space-name');
      return allowedSpaces.includes(currentSpace);
    },
    tableFieldsComputed() {
      return this.isTipicoSpace ? ['memberId'] : ['memberId', 'spinsLeft'];
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      }
    },
  },
  created() {
    this.fetchEntrants();
  },
  methods: {
    async fetchEntrants() {
      const token = localStorage.getItem('vue-token');
      const hostname = window.location.hostname;
      const isStagingDev = hostname.includes('127.0.0.1');
      const isStaging = hostname.split('.').includes('staging');

      const baseUrl = isStagingDev || isStaging
        ? `https://api.staging.ziqni.io`
        : `https://api.ziqni.com`;
      try {
        const path = `${baseUrl}/instant-wins/entrants-info`;
        const response = await axios.get(path, {
          headers: { 'Authorization': `Bearer ${ token }` },
          params: {
            id: this.$route.params.id,
            _limit: this.itemsPerPage,
            _skip: (this.page - 1) * this.itemsPerPage,
          }
        });

        this.pages = Math.ceil(response.data.meta.totalRecords / this.itemsPerPage);
        this.totalRecords = response.data.meta.totalRecords;
        this.entrantsData = response.data.results;
        this.ready = true;
      } catch (error) {
        console.error('Error fetching entrants:', error);
      }
    },
    async itemsPerPageSelect(val) {
      if (val) {
        this.itemsPerPage = val;
        this.page = 1;
        await this.fetchEntrants();
      }

    },
    async paginationChange(val) {
      this.page = val;
      await this.fetchEntrants();
    },
    async searchData(value) {
      this.query = value.trim() ? [{ queryFields: this.searchableAllFields, queryValue: value }] : null;
      await this.fetchEntrants();
    },
    async handleReload() {
      await this.fetchEntrants();
    },
    async handleSortBy(e) {
      this.sortBy = [{ queryField: e.column, order: e.asc ? 'Asc' : 'Desc' }];
      this.page = 1;
      await this.fetchEntrants();
    },
    async showColumnFilter() {
      this.columnFilter = !this.columnFilter;
      if (!this.columnFilter) await this.fetchEntrants();
    },
    async handleColumnFilter(e) {
      this.multiFields = e.field && e.value ? [{ queryFields: [e.field], queryValue: e.value }] : [];
      await this.fetchEntrants();
    }
  }
};
</script>

<style scoped lang="scss">
.entrants {
  &--actions-header {
    display: flex;
    justify-content: space-between;
  }
}

.zq-card-row--copy-icon {
  margin-left: 0.5rem;
  cursor: copy;
}
</style>
