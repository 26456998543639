<template>
  <div class="preview">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewPage.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" @update:activeTab="updateActiveTab" ref="instantWinsTabs">
      <CTab :title="texts.previewPage.instantWinTab">
        <InstantWinDetails v-if="isInstantWin"  />
      </CTab>
      <CTab :title="texts.previewPage.entrantsTab" v-if="!isTipicoSpace">
        <InstantWinMembersTable v-if="isEntrants" :entityId="$route.params.id" :entityType="'InstantWin'" />
      </CTab>
      <CTab :title="texts.previewPage.awardsTab">
        <AwardsDetails v-if="isAwards" :entityType="'InstantWin'" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import InstantWinDetails from './InstantWinDetails';
import InstantWinMembersTable from './InstantWinMembersTable';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import AwardsDetails from '@/views/ziqni/achievements/AwardsDetails.vue';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import { instantWins } from '@/config/descriptions/instantWins.json';

export default {
  name: 'PreviewInstantWin' ,
  components: { AwardsDetails, IconWithTooltip, InstantWinDetails, InstantWinMembersTable },
  data() {
    return {
      descriptions: {
        ...instantWins.preview
      },
      texts: {
        ...instantWinsTexts
      },
      isInstantWin: false,
      isEntrants: false,
      isAwards: false,
      tabsMap: {
        0: 'isInstantWin',
        1: 'isEntrants',
        2: 'isAwards',
      },
    }
  },
  mounted() {
    this.isInstantWin = true;
  },
  computed: {
    isTipicoSpace() {
      const allowedSpaces = [];
      const currentSpace = localStorage.getItem('zq-space-name');

      return allowedSpaces.includes(currentSpace);
    },
    filteredTabsMap() {
      let index = 0;
      const map = {};
      if (true) {
        map[index++] = 'isInstantWin';
      }
      if (!this.isTipicoSpace) {
        map[index++] = 'isEntrants';
      }
      map[index] = 'isAwards';
      return map;
    }
  },
  methods: {
    updateActiveTab(val) {
      const key = this.filteredTabsMap[val];

      this.isInstantWin = false;
      this.isEntrants = false;
      this.isAwards = false;

      if (key) {
        this[key] = true;
      }
    },
  }
};
</script>

<style scoped lang="scss">

</style>
